<template>
	<el-button type="primary" :loading="downloadLoading" @click="exportExcel">
		导出
	</el-button>
</template>

<script>
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default {
	props: {
		id: {
			type: String, //table ID名称
			default: "table",
		},
		name: {
			type: String, //导出需要的名字
			default: "Table",
		},
	},
	data() {
		return {
			downloadLoading: false,
		};
	},
	components: {},
	methods: {
		// 导出Excel表格
		exportExcel() {
			this.downloadLoading = true;

			// 获取表格元素
			const table = document.querySelector("#" + this.id);

			// 将表格转换为工作簿对象
			let wb = XLSX.utils.table_to_book(table, { raw: true });

			// 获取第一个工作表
			let ws = wb.Sheets[wb.SheetNames[0]];

			// 遍历工作表数据并修改“预览”列
			const range = XLSX.utils.decode_range(ws["!ref"]);
			for (let R = range.s.r + 1; R <= range.e.r; ++R) {
				const idCell = ws[XLSX.utils.encode_cell({ r: R, c: 0 })]; // 假设“id”在第一列
				const previewCell = ws[XLSX.utils.encode_cell({ r: R, c: 7 })]; // 假设“预览”在第七列
				const optCell = ws[XLSX.utils.encode_cell({ r: R, c: 8 })]; // 假设“预览”在第七列
				if (optCell.v.includes("已推送")) {
					optCell.v = "已推送";
				} else {
					optCell.v = "未推送";
				}

				if (idCell && previewCell) {
					previewCell.v = "https://web.metaera.hk/article/" + idCell.v; // 修改“预览”列内容为“id”列加上“abc”
				}
			}

			// 获取二进制字符串作为输出
			var wbout = XLSX.write(wb, {
				bookType: "xlsx",
				bookSST: true,
				type: "array",
			});

			try {
				FileSaver.saveAs(
					new Blob([wbout], {
						type: "application/octet-stream",
					}),
					this.name + ".xlsx"
				);
			} catch (e) {
				if (typeof console !== "undefined") console.log(e, wbout);
			}

			this.downloadLoading = false;
			return wbout;
		},
	},
};
</script>
